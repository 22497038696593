
<label [ngClass]="['searchbox', (!!searchInput.value ? 'dirty' : 'clean'), ((burgerMenuOpen$ | async) ? 'burger-menu-open' : 'burger-menu-closed')]">
  <input
    #searchInput
    [placeholder]="'customSearchbox.searchPlaceholder' | cxTranslate"
    autocomplete="off"
    aria-describedby="initialDescription"
    aria-controls="results"
    [attr.aria-label]="'common.search' | cxTranslate"
    (focus)="open()"
    (click)="open()"
    (input)="search(searchInput.value)"
    (blur)="close($event)"
    (keydown.escape)="close($event)"
    (keydown.enter)="
      close($event, true);
      launchSearchResult($event, searchInput.value);
      updateChosenWord(searchInput.value)
    "
    (keydown.arrowup)="focusPreviousChild($event)"
    (keydown.arrowdown)="focusNextChild($event)"
    value="{{ chosenWord }}"
  />

  <button
    [attr.aria-label]="'common.reset' | cxTranslate"
    (mousedown)="clear(searchInput)"
    (keydown.enter)="clear(searchInput)"
    class="reset"
  >
    <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
  </button>

  <div role="presentation" class="search-icon">
    <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
  </div>

  <button
    [attr.aria-label]="'common.search' | cxTranslate"
    class="search"
    (click)="open()"
  >
    <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
  </button>
</label>


<div
  *ngIf="results$ | async as result"
  class="results"
  id="results"
  (click)="close($event, true)"
  role="listbox"
>
  <div *ngIf="result.suggestions?.length === 0 && result.products?.length === 0 " class="message" >
    {{ 'customSearchbox.noResultsFound' | cxTranslate }}
  </div>

  <ul
    class="suggestions"
    attr.aria-label="{{ 'searchBox.ariaLabelSuggestions' | cxTranslate }}"
    tabindex="0"
  >
    <li *ngFor="let suggestion of result.suggestions">
      <a
        class="suggestion-result-row"
        [routerLink]=" { cxRoute: 'search', params: { query: suggestion } } | cxUrl "
        (keydown.arrowup)="focusPreviousChild($event)"
        (keydown.arrowdown)="focusNextChild($event)"
        (keydown.enter)="close($event, true)"
        (keydown.escape)="close($event, true)"
        (blur)="close($event)"
        (mousedown)="preventDefault($event)"
        (click)="
          dispatchSuggestionEvent({
            freeText: searchInput.value,
            selectedSuggestion: suggestion,
            searchSuggestions: result.suggestions
          });
          updateChosenWord(suggestion)
        "
      >
        {{ suggestion }}
        <span class="search-indicator">{{ 'customSearchbox.searchIndicators.keyword' | cxTranslate }}</span>
      </a>
    </li>
  </ul>

  <ul
    class="products"
    *ngIf="result.products"
    attr.aria-label="{{ 'searchBox.ariaLabelProducts' | cxTranslate }}"
    tabindex="0"
  >
    <li *ngFor="let product of result.products">
      <a
        class="product-result-row"
        [routerLink]=" { cxRoute: 'product', params: product } | cxUrl "
        [class.has-media]="config.displayProductImages"
        (keydown.arrowup)="focusPreviousChild($event)"
        (keydown.arrowdown)="focusNextChild($event)"
        (keydown.enter)="close($event, true)"
        (keydown.escape)="close($event, true)"
        (blur)="close($event)"
        (mousedown)="preventDefault($event)"
        (click)="
        dispatchProductEvent({
          freeText: searchInput.value,
          productCode: product.code
        })
        "
      >
        <cx-media
          *ngIf="config.displayProductImages"
          [container]="product.thumbnail"
          format="thumbnail"
          role="presentation"
        ></cx-media>
        <div class="name" [innerHTML]="product.nameHtml"></div>
        <span class="search-indicator">{{ 'customSearchbox.searchIndicators.product' | cxTranslate }}</span>
      </a>
    </li>
  </ul>
  <span id="initialDescription" class="cx-visually-hidden">
    {{ 'searchBox.initialDescription' | cxTranslate }}
  </span>
  <div
    *ngIf="result.suggestions?.length || result.products?.length"
    aria-live="assertive"
    class="cx-visually-hidden"
  >
    {{ 'searchBox.suggestionsResult' | cxTranslate: { count: result.suggestions?.length } }}
    {{ 'searchBox.productsResult' | cxTranslate: { count: result.products?.length } }}
    {{ 'searchBox.initialDescription' | cxTranslate }}
  </div>
</div>

