import { Component, ElementRef, HostBinding, HostListener, inject, } from '@angular/core';
import { CmsSearchBoxComponent, RoutingService, WindowRef } from '@spartacus/core';
import { CustomPreHeaderService } from '../custom-pre-header/custom-pre-header.service';
import { filter, take } from 'rxjs';
import { CmsComponentData, SearchBoxComponent, SearchBoxComponentService, SearchBoxProductSelectedEvent, SearchBoxSuggestionSelectedEvent } from '@spartacus/storefront';
import { getSyncValue } from '@util/functions/rxjs-custom.operators';
import { UtilElementHelper } from '@util/functions/elements';

/**
  * The mapping of the component facilitates the style and behaviour
  * changes of the Searchbox in the Header, as well as some fields for translations.
  * For now the function to add facets to the suggestions has not
  * been implemented, so the logic of it has not changed.
  */
@Component({
  selector: 'app-custom-searchbox',
  templateUrl: './custom-searchbox.component.html',
  styleUrls: ['./custom-searchbox.component.scss']
})
export class CustomSearchboxComponent extends SearchBoxComponent {

  private _elementRef = inject(ElementRef);
  private openingLock = false;

  @HostBinding('class')
  private get clazzes(): string[] {

    const resultLength = getSyncValue(this.results$, res => ((res.products?.length || 0) + (res.suggestions?.length || 0)));

    return [
      resultLength ? 'has-searchbox-results' : 'no-searchbox-results',
      this.searchBoxActive ? 'searchbox-is-active' : 'searchbox-is-inactive'
    ];
  }

  burgerMenuOpen$ = this.customPreHeaderService.open$;

  constructor(
    searchBoxComponentService: SearchBoxComponentService,
    componentData: CmsComponentData<CmsSearchBoxComponent>,
    winRef: WindowRef,
    routingService: RoutingService,
    private customPreHeaderService: CustomPreHeaderService
  ) {
    super(searchBoxComponentService, componentData, winRef, routingService);

  }

  /**
   * extending the class broke correct usage of clickout()
   * but seemed unnecessary anyway
   */
  override clickout(event: UIEvent) {
    return;
  }

  /**
   * opening, only if lock is not active
   */
  override open() {
    if (!this.openingLock) {
      super.open();
    }
  }

  /**
   * softClose() also activates openingLock for this cycle
   */
  override softClose() {
    super.softClose();
    this.openingLock = true;
    setTimeout(() => {
      this.openingLock = false;
    }, 0);
  }

  override dispatchSuggestionEvent(eventData: SearchBoxSuggestionSelectedEvent) {
    this.emptyTextboxOnNextPage(1000);
    super.dispatchSuggestionEvent(eventData);
  }

  override dispatchProductEvent(eventData: SearchBoxProductSelectedEvent) {
    this.emptyTextboxOnNextPage();
    super.dispatchProductEvent(eventData);
  }

  /**
   * needs to be overridden because custom component has a different html structure
   */
  protected override getResultElements(): HTMLElement[] {
    const host = (this._elementRef.nativeElement as HTMLElement);
    const resultQuery = `
      label.searchbox > input,
      ul.suggestions:not(.hidden) > li a,
      ul.products > li a
    `;
    return Array.from(host.querySelectorAll(resultQuery));
  }


  emptyTextboxOnNextPage(delay?: number) {
    this.routingService.getNextPageContext().pipe(
      filter(c => !!c),
      take(1)
    ).subscribe(context => {

      if (typeof delay === 'number') {
        setTimeout(() => {
          const inputEl = (this._elementRef.nativeElement as HTMLElement).querySelector('input');
          inputEl.value = '';
        }, delay);
      } else {
        const inputEl = (this._elementRef.nativeElement as HTMLElement).querySelector('input');
        inputEl.value = '';
      }
    });
  }

}
